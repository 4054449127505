export const baseUrl = {
    DEVELOPMENT_API_URL: process.env.REACT_APP_BASE_URL
}

export const mapURl = {
    DEVELOPMENT_MAP_URL: { google: process.env.REACT_APP_MAP_URL }
}

export const hmacKey = {
    DEVELOPMENT_HMAC_KEY: process.env.REACT_APP_HMAC_Key
}

export const measurementID = {
    DEVELOPMENT_MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID
}