import { authenticationService } from '../services/authentication.service';
import { history } from "../components/History.js"

export function handleResponse(response) {    
    if (!response.ok) {        
        if ([401].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            authenticationService.signOut();
            window.location.replace(window.location.origin);
            return Promise.reject(response.statusText);
        }
    }
    return response;
}

