import { fetchWrapper } from '../helpers/fetchWrapper';

export class CommonServices {

    constructor(url) {
        this.apiUrl = url;
    }

    GetList() {
        return fetchWrapper.get(this.apiUrl + '/GetList').then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }

    GetListWithID(id) {
        return fetchWrapper.get(this.apiUrl + '/GetList/' + id).then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }

    GetModel(id) {
        return fetchWrapper.get(this.apiUrl + '/GetModel/' + id).then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }

    GetLookupList() {
        return fetchWrapper.get(this.apiUrl + '/GetLookupList').then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }

    InsertRecord(params) {
        return fetchWrapper.post(this.apiUrl + '/Insert', params).then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }

    UpdateRecord(params) {
        return fetchWrapper.put(this.apiUrl + '/Update', params).then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }

    DeleteRecord(id) {
        return fetchWrapper.delete(`${this.apiUrl}/Delete/${id}`).then((res) => {
            return res;
        })
            .catch((error) => {
                console.log(error)
            });
    }
}
