import { useState, useCallback, useEffect } from 'react';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const onSizeChanged = useCallback(() => {
    setScreenSize(getScreenSize());
  }, []);

  useEffect(() => {
    subscribe(onSizeChanged);

    return () => {
      unsubscribe(onSizeChanged);
    };
  }, [onSizeChanged]);

  return screenSize;
};

export const useScreenSizeClass = () => {
  const screenSize = useScreenSize();

  if (screenSize.isExLarge) {
    return 'screen-ex-large';
  }
  if (screenSize.isLarge) {
    return 'screen-large';
  }

  if (screenSize.isMedium) {
    return 'screen-medium';
  }

  if (screenSize.isSmall) {
    return 'screen-small';
  }
  if (screenSize.isXSmall) {
    return 'screen-x-small';
  }
  if (screenSize.isXXSmall) {
    return 'screen-xx-small';
  }
  return 'screen-ex-small';
}

let handlers = [];
const exSmallMedia = window.matchMedia('(max-width: 374px)'); /* Mobile screen less than 374 */
const xxSmallMedia = window.matchMedia('(min-width: 375px) and (max-width: 479px)') /* Mobile screen between 375 & 480 */
const xSmallMedia = window.matchMedia('(min-width: 480px) and (max-width: 767px)'); /* Mobile between 480 & 768 */
const smallMedia = window.matchMedia('(min-width: 768px) and (max-width: 1023px)'); /* Small screen between 768 & 1024 like tablet */
const mediumMedia = window.matchMedia('(min-width: 1024px) and (max-width: 1365px)'); /* Medium screen between 1024 & 1366 like ipad pro, small laptop */
const largeMedia = window.matchMedia('(min-width: 1366px) and (max-width: 1919px)'); /* Large screen between 1366 & 1920 like laptop, desktop */
const exLargeMedia = window.matchMedia('(min-width: 1920px)');  /* Wide screen greater than 1920 */

[exSmallMedia,xxSmallMedia, xSmallMedia, smallMedia, mediumMedia, largeMedia, exLargeMedia].forEach(media => {
  
  media.addListener((e) => {    
    e.matches && handlers.forEach(handler => handler());
  });
});

const subscribe = handler => handlers.push(handler);

const unsubscribe = handler => {
  handlers = handlers.filter(item => item !== handler);
};

function getScreenSize() {
  
  return {
    isExSmall: exSmallMedia.matches,
    isXXSmall :xxSmallMedia.matches,
    isXSmall: xSmallMedia.matches,
    isSmall: smallMedia.matches,
    isMedium: mediumMedia.matches,
    isLarge: largeMedia.matches,
    isExLarge: exLargeMedia.matches
  };
}
