import ReactGA from 'react-ga4';
import { measurementID } from '../../BaseUrl';

// Replace with your actual Measurement ID
const MEASUREMENT_ID = measurementID.DEVELOPMENT_MEASUREMENT_ID;

export const initGA = () => {
    ReactGA.initialize(MEASUREMENT_ID);
};

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

// Track user login
export const trackLogin = (emailID) => {
    ReactGA.event({
        category: 'User',
        action: 'Login',
        label: `Email ID: ${emailID}`,
    });
};

export const logEvent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };


  // src/analyticsConstants.js
export const ANALYTICS_CATEGORIES = {
    BUTTON_CLICKS: 'Button Clicks',
    FORM_SUBMISSIONS: 'Form Submissions',
    NAVIGATION: 'Navigation',
    // Add more categories as needed
  };
  
  export const ANALYTICS_ACTIONS = {
    CLICK: 'Click',
    SUBMIT: 'Submit',
    NAVIGATE: 'Navigate',
    // Add more actions as needed
  };

   
  // Example of predefined events
  export const trackButtonClick = (buttonName) => {
    logEvent(ANALYTICS_CATEGORIES.BUTTON_CLICKS, ANALYTICS_ACTIONS.CLICK, buttonName);
  };
  
  export const trackFormSubmit = (formName) => {
    logEvent(ANALYTICS_CATEGORIES.FORM_SUBMISSIONS, ANALYTICS_ACTIONS.SUBMIT, formName);
  };

  export const trackMenuClick = (menuName) => {
    logEvent(ANALYTICS_CATEGORIES.NAVIGATION, ANALYTICS_ACTIONS.NAVIGATE, menuName);
  };