import { authenticationService } from "../services/authentication.service";
import { authHeader } from "./authHeader";
import { handleResponse } from "./handleResponse";


// Get Request With Header
const get = (url) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),    
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Get Request Without Header
const getWithoutHeader = (url) => {
  const requestOptions = {
    method: "GET",
    headers: {     
      'Content-Type': 'application/json'
    }, 
  };  
  return fetch(url, requestOptions).then(handleResponse);
}

// Get Request Without Header and Auth
const getWithoutAuthAndHeader = (url) => {
  const requestOptions = {
    method: "GET",   
  };  
  return fetch(url, requestOptions).then(handleResponse);
}


// Post Request With Header
const post = (url, body) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Post Request With Header and For File Attachment
const postwithFile = (url, body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authenticationService.currentUserValue?.authToken}`
    }, 
    body: body,
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Post Request With File Attachment and No Authentication
const postwithFileandNoAuth = (url, body) => {
  const requestOptions = {
    method: "POST",
     body: body,
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Post Request With Header and parameters
const postWithParams = (url) => {    
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Post Request Without Header
const postWithoutHeader = (url, body) => {
  const requestOptions = {
    method: "POST",
    headers: {     
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify(body),
  };  
  return fetch(url, requestOptions).then(handleResponse);
}

// Put Request With Header
const put = (url, body) => {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Put Request Without Header
const putWithoutHeader = (url, body) => {
  const requestOptions = {
    method: "PUT",
    headers: {     
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify(body),
  };  
  return fetch(url, requestOptions).then(handleResponse);
}

// Put Request With Header and For File Attachment
const putwithFile = (url, body) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authenticationService.currentUserValue?.authToken}`
    }, 
    body: body,
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
// Delete Request With Header
const _delete = (url) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Delete Request With Header and parameters
const _deleteWithBody = (url, body) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// Delete Request Without Header and parameters
const _deleteWithoutHeader = (url) => {
  const requestOptions = {
    method: "DELETE",  
  };
  return fetch(url, requestOptions).then(handleResponse);
}

export const fetchWrapper = {
  get,
  getWithoutHeader,
  getWithoutAuthAndHeader,
  post,
  postwithFile,
  postWithParams,
  postWithoutHeader,
  postwithFileandNoAuth,
  put,
  putWithoutHeader,
  putwithFile,
  delete: _delete,
  deleteWithBody: _deleteWithBody,
  deleteWithoutHeader:_deleteWithoutHeader
};
