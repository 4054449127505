import { BehaviorSubject } from 'rxjs';
import { baseUrl } from "../BaseUrl";
import { LicenseService } from './license.service';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
let url = `${baseUrl.DEVELOPMENT_API_URL}UserManagement/AuthenticateUser`;

export const authenticationService = {
    signIn,
    signOut,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

const licenseService = new LicenseService();

function signIn(emailid, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailid, password })
    };

    return fetch(url, requestOptions).then((response) => {
        if (!response.ok && [401, 400].indexOf(response.status) === -1) throw new Error(response.status);
        else if ([401].indexOf(response.status) !== -1) {
            return response;
        }
        else if ([400].indexOf(response.status) !== -1) {
            return response;
        }
        else {
            return response.json();
        }
    })
        .then((user) => {
            if (user && user.ok === undefined) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                currentUserSubject.next(user);

            }
            return user;
        })
        .catch((error) => {
            console.log('error: ' + error);
        });
}

function signOut() {       
    if (authenticationService?.currentUserValue && authenticationService?.currentUserValue?.tokenGeneratedTime) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${authenticationService.currentUserValue?.authToken}`,
                'Content-Type': 'application/json'
            }
        };
        let urlToPut = `${baseUrl.DEVELOPMENT_API_URL}Logs/LoginLog/UpdateLogoutDateTime/${authenticationService?.currentUserValue?.tokenGeneratedTime}`
        return fetch(urlToPut, requestOptions).then((response) => {            
            if (!response.ok && [404,400].indexOf(response.status) === -1) throw new Error(response.status);
            else if ([404].indexOf(response.status) !== -1) { return response; }
            else if ([400].indexOf(response.status) !== -1) { return response; }
            else return response.json();
        }).then((user) => {            
            shutdown()
            Intercom('show')
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentUserID');
            currentUserSubject.next(null);
        })
            .catch((error) => {
                console.log('error: ' + error);
            });
    } else {
        shutdown()
        Intercom('show')
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserID');
        currentUserSubject.next(null);
    }
}
