import { authenticationService } from '../services/authentication.service';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.authToken) {
        return {
            Authorization: `Bearer ${currentUser.authToken}`,
            'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}