import React, { createContext, useContext, useState } from 'react';

// Create a Context
export const HelpContentContext = createContext();

// Create a provider component
export const HelpContentProvider = ({ children }) => {
 
  const [HelpContentMenuID, setHelpContentMenuID] = useState(null);
  const [HelpContentSectionID, setHelpContentSectionID] = useState(null);

  return (
    <HelpContentContext.Provider value={{ HelpContentMenuID, setHelpContentMenuID, HelpContentSectionID, setHelpContentSectionID }}>
      {children}
    </HelpContentContext.Provider>
  );
};