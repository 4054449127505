import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

export const GoBackToNoPermission = () => {
    history.push("/NoPermission");    
}

export const GoBackToBlankPermission = () => {
    history.push("/");    
}