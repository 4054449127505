import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'devextreme/dist/css/dx.light.css';
import './dxStyle.scss'
import { history } from './components/History';
import "../node_modules/ace-builds/css/ace.css";  
import "../node_modules/ace-builds/css/theme/dreamweaver.css";  
import "../node_modules/ace-builds/css/theme/ambiance.css"; 

ReactDOM.render(
  <Router history={history} >
    <App />
  </Router>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
